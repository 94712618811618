(function(){function r(e,n,t){function o(i,f){if(!n[i]){if(!e[i]){var c="function"==typeof require&&require;if(!f&&c)return c(i,!0);if(u)return u(i,!0);var a=new Error("Cannot find module '"+i+"'");throw a.code="MODULE_NOT_FOUND",a}var p=n[i]={exports:{}};e[i][0].call(p.exports,function(r){var n=e[i][1][r];return o(n||r)},p,p.exports,r,e,n,t)}return n[i].exports}for(var u="function"==typeof require&&require,i=0;i<t.length;i++)o(t[i]);return o}return r})()({1:[function(require,module,exports){
"use strict";

// add google analytics for web properties
document.addEventListener('DOMContentLoaded', dom => {
  // we only track myMaxwellClass
  if (location.hostname == 'mymaxwellclass.com') {
    // analytics source
    const gtag = document.createElement('script');
    gtag.async = true;
    gtag.src = 'https://www.googletagmanager.com/gtag/js?id=G-FK442CE31W';

    // analytics tag
    const script = document.createElement('script');
    script.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){dataLayer.push(arguments);}
    gtag('js', new Date());
    gtag('config', 'G-FK442CE31W');`;

    // append to top of head
    document.head.insertBefore(script, document.head.firstChild);
    document.head.insertBefore(gtag, document.head.firstChild);
  }
});
},{}],2:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
/* Example Usage:

document.getElementById('in').addEventListener('click', function() {
  animation.fadeIn(document.getElementById('test'), {
    duration: 2000,
    complete: function() {
      alert('Complete');
    }
  });
}, false);


document.getElementById('out').addEventListener('click', function() {
  animation.fadeOut(document.getElementById('test'), {
    duration: 2000,
    complete: function() {
      alert('Complete');
    }
  });
}, false);

*/

const animation = {
  easing: {
    linear: function (progress) {
      return progress;
    },
    quadratic: function (progress) {
      return Math.pow(progress, 2);
    },
    swing: function (progress) {
      return 0.5 - Math.cos(progress * Math.PI) / 2;
    },
    circ: function (progress) {
      return 1 - Math.sin(Math.acos(progress));
    },
    back: function (progress, x) {
      return Math.pow(progress, 2) * ((x + 1) * progress - x);
    },
    bounce: function (progress) {
      for (var a = 0, b = 1, result; 1; a += b, b /= 2) {
        if (progress >= (7 - 4 * a) / 11) {
          return -Math.pow((11 - 6 * a - 11 * progress) / 4, 2) + Math.pow(b, 2);
        }
      }
    },
    elastic: function (progress, x) {
      return Math.pow(2, 10 * (progress - 1)) * Math.cos(20 * Math.PI * x / 3 * progress);
    }
  },
  animate: function (options) {
    var start = new Date();
    var id = setInterval(function () {
      var timePassed = new Date() - start;
      var progress = timePassed / options.duration;
      if (progress > 1) {
        progress = 1;
      }
      options.progress = progress;
      var delta = options.delta(progress);
      options.step(delta);
      if (progress == 1) {
        clearInterval(id);
        options.complete();
      }
    }, options.delay || 10);
  },
  fadeOut: function (element, options) {
    var to = 1;
    this.animate({
      duration: options.duration,
      delta: function (progress) {
        progress = this.progress;
        return animation.easing.swing(progress);
      },
      complete: options.complete,
      step: function (delta) {
        element.style.opacity = to - delta;
      }
    });
  },
  fadeIn: function (element, options) {
    var to = 0;
    this.animate({
      duration: options.duration,
      delta: function (progress) {
        progress = this.progress;
        return animation.easing.swing(progress);
      },
      complete: options.complete,
      step: function (delta) {
        element.style.opacity = to + delta;
      }
    });
  }
};
var _default = exports.default = {
  animation
};
},{}],3:[function(require,module,exports){
"use strict";

require("core-js/modules/es.array.push.js");
require("./select");
var _day = _interopRequireDefault(require("./day"));
var _display = _interopRequireDefault(require("./display"));
var _schedule = require("./schedule");
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
// builds appointment form for nmax/kmax
document.addEventListener('DOMContentLoaded', dom => {
  // appointment form for nathan, kathy or modal
  const nmax = document.getElementById('nmax');
  const kmax = document.getElementById('kmax');
  const prof = document.getElementById('profappt');
  const form = document.getElementById('schedule-appointment-form');
  if ((nmax || kmax || prof) && form) {
    // disect time string for parsing
    const aptparse = time => {
      const meridian = time.slice(-2);
      const base = time.substr(0, time.length - 3).split(':');
      const hour = Number(base[0]);
      const mins = base[1];
      const parsed = {
        "meridian": meridian,
        "base": base,
        "hour": hour,
        "mins": mins
      };
      return parsed;
    };

    // compiles <select> <options> with start time and 15min increments (ex: '9:00 AM', 12)
    const aptoptadd = apts => {
      // construct opts for specified increments (duration)
      const buildopts = (start, duration, shard) => {
        // init
        let t = start;
        let time = aptparse(t);
        let opts = '';
        let term = '';

        // build opts
        for (let i = 0; i < duration; i++) {
          // add option, assign shard if multidimetional (split) schedule
          opts += `<option ${shard !== false ? `data-shard="${shard}" ` : ''} value="${t}">${t}</option>`;

          // increment minute
          switch (time.mins) {
            case '00':
              time.mins = '15';
              break;
            case '15':
              time.mins = '30';
              break;
            case '30':
              time.mins = '45';
              break;
            case '45':
              time.mins = '00';
              break;
          }
          ;

          // increment hour
          if (time.mins == '00') {
            time.hour = time.hour == 12 ? 1 : time.hour + 1;
          }
          ;

          // update meridian
          if (time.hour == 12 && time.mins == '00') {
            time.meridian = time.meridian == 'AM' ? 'PM' : 'AM';
          }
          ;

          // construct new option value
          t = `${time.hour}:${time.mins} ${time.meridian}`;

          // set threshold for aptoptdel
          if (i == duration - 1) {
            term = t;
          }
          ;
        }
        ;
        return {
          opts: opts,
          term: term
        };
      };

      // init add options
      let options = '';
      let terminus = '';
      let config = {};

      // build options
      if (apts.filter(Array.isArray).length !== 0) {
        // multidimetional arrays for split schedules, e.g. [ ['9:30 AM', 4], ['12:30 PM', 8] ]
        let opts = '';
        const term = [];
        apts.map((shard, id) => {
          config = buildopts(shard[0], shard[1], id);
          opts += config.opts;
          term.push(config.term);
        });
        options = opts;
        terminus = term;
      } else {
        // simple array, [0]=time, [1]=duration, e.g. [ '10:00 AM', 8 ]
        config = buildopts(apts[0], apts[1], false);
        options = config.opts;
        terminus = config.term;
      }
      return {
        options: `<option value="">What time?</option>${options}`,
        terminus: terminus
      };
    };

    // eliminates appointment durations that exceed schedule
    const aptoptdel = threshold => {
      // delete options
      const delopt = threshold => {
        // init
        let time = aptparse(threshold);
        let t = new Array();
        let mins = new Array(60, 45, 30);
        let tmap = new Array();

        // construct opt candidates for deletion
        for (let i = 0; i < 3; i++) {
          // deincrement minute
          switch (time.mins) {
            case '00':
              time.mins = '45';
              break;
            case '15':
              time.mins = '00';
              break;
            case '30':
              time.mins = '15';
              break;
            case '45':
              time.mins = '30';
              break;
          }
          ;

          // deincrement hour
          if (time.mins == '45') {
            time.hour = time.hour == 1 ? 12 : time.hour - 1;
          }
          ;

          // update meridian
          if (time.hour == 11 && time.mins == '45') {
            time.meridian = time.meridian == 'AM' ? 'PM' : 'AM';
          }
          ;

          // deincrement time
          t.push(`${time.hour}:${time.mins} ${time.meridian}`);
        }
        ;

        // start with selects most distant from threshold
        tmap = t.reverse();

        // disable durations exceeding threshold
        if (timeSelect.contains(tmap[0]) && tmap[0] == timeSelected) {
          durationSelect.hide(mins[0]);
        } else if (timeSelect.contains(tmap[1]) && tmap[1] == timeSelected) {
          durationSelect.hide(mins[0]);
          durationSelect.hide(mins[1]);
        } else if (timeSelect.contains(tmap[2]) && tmap[2] == timeSelected) {
          durationSelect.hide(mins[0]);
          durationSelect.hide(mins[1]);
          durationSelect.hide(mins[2]);
        } else if (!timeSelected) {
          durationSelect.setAttribute('disabled', true);
        } else {
          durationSelect.show(true);
        }
      };

      // parse terminus for target deletions
      if (Array.isArray(threshold)) {
        // multidimetional (split) schedule, match terminus to options shard
        const timeSelector = document.getElementById('time');
        var shard = false;
        for (var i = 0; i < timeSelector.options.length; i++) {
          if (timeSelector.options[i].value == timeSelected) {
            shard = timeSelector.options[i].dataset.shard;
          }
        }
        if (shard !== false) {
          delopt(threshold[shard]);
        }
        ;
      } else {
        // simple schedule terminus
        delopt(threshold);
      }
    };

    // build appointment schedule
    const mapSchedule = schedule => {
      const map = new Object();
      for (const [day, apts] of Object.entries(schedule)) {
        map[day] = Array.isArray(apts) ? aptoptadd(apts) : apts;
      }
      return map;
    };

    // init config
    const aptmap = nmax || prof ? mapSchedule(_schedule.nathan) : mapSchedule(_schedule.kathy);
    var schedule,
      timeSelected = false;

    // get base <select> elements
    const daySelect = document.getElementById('day');
    const timeSelect = document.getElementById('time');
    const durationSelect = document.getElementById('duration');
    const modalitySelect = document.getElementById('modality');

    // form init
    daySelect.clear();
    timeSelect.clear();
    durationSelect.clear();
    modalitySelect.clear();
    timeSelect.disabled = true;
    durationSelect.disabled = true;
    (0, _display.default)(document.querySelectorAll('label'), false);

    // when day is selected, enable time selection and populate office hours
    daySelect.addEventListener('change', e => {
      let day = event.target.value;
      schedule = aptmap[(0, _day.default)(day)];
      if (schedule && day.length > 0) {
        timeSelect.innerHTML = schedule.options;
        timeSelect.disabled = false;
        durationSelect.clear();
        durationSelect.disabled = true;
      } else {
        timeSelect.disabled = durationSelect.disabled = true;
        timeSelect.clear();
        durationSelect.clear();
      }
    });

    // on time selection, enable duration selection and disable durations exceeding office hours
    timeSelect.addEventListener('change', e => {
      timeSelected = timeSelect.options[timeSelect.selectedIndex].value;
      const durationOptions = Array.from(durationSelect.children);
      durationOptions.forEach(durationOption => {
        durationOption.hidden = false;
      });
      let selection = event.target.value;
      durationSelect.disabled = false;
      durationSelect.clear();
      if (selection.length > 0) {
        aptoptdel(schedule.terminus);
      } else {
        durationSelect.disabled = true;
      }
    });
  }
});
},{"./day":9,"./display":10,"./schedule":20,"./select":21,"core-js/modules/es.array.push.js":95}],4:[function(require,module,exports){
"use strict";

window.addeventasync = () => {
  addeventatc.settings({
    license: 'axhb4ndx6z1rg6l68mpy257',
    mouse: false,
    css: false,
    appleical: {
      show: true,
      text: 'Apple Calendar'
    },
    office365: {
      show: true,
      text: 'Office 365'
    },
    outlook: {
      show: true,
      text: 'Outlook'
    },
    outlookcom: {
      show: true,
      text: 'Outlook.com'
    },
    facebook: {
      show: true,
      text: 'Facebook Event'
    },
    google: {
      show: true,
      text: 'Google'
    },
    yahoo: {
      show: true,
      text: 'Yahoo'
    },
    dropdown: {
      order: 'outlookcom,outlook,office365,google,appleical,facebook,yahoo'
    }
  });
};
},{}],5:[function(require,module,exports){
"use strict";

require("core-js/modules/es.array.push.js");
// adapted from https://gist.github.com/kdzwinel/426a0f76f113643fa285

(function () {
  "use strict";

  const dropClasses = ['fab', 'fad', 'fal'];
  const dropRexExps = [/fa-*/];
  const dropClass = classes => {
    dropClasses.map(cls => {
      let index = classes.indexOf(cls);
      if (index > -1) {
        classes.splice(index, 1);
      }
    });
    return classes;
  };
  const dropRegExp = classes => {
    const results = [];
    classes.map(cls => {
      const match = dropRexExps.some(regex => regex.test(cls));
      if (!match) {
        results.push(cls);
      }
    });
    return results;
  };

  //get all unique CSS classes defined in the main document
  let allClasses = Array.from(document.querySelectorAll('*')).map(n => Array.from(n.classList)).reduce((all, a) => all ? all.concat(a) : a).reduce((all, i) => all.add(i), new Set());

  //load contents of all CSS stylesheets applied to the document
  let loadStyleSheets = Array.from(document.styleSheets).map(s => {
    if (s.href) {
      return fetch(s.href).then(r => r.text()).catch(e => {
        console.warn('Coudn\'t load ' + s.href + ' - skipping');
        return "";
      });
    }
    return s.ownerNode.innerText;
  });
  Promise.all(loadStyleSheets).then(s => {
    let text = s.reduce((all, s) => all + s);

    //get a list of all CSS classes that are not mentioned in the stylesheets
    let undefinedClasses = Array.from(allClasses).filter(c => {
      var rgx = new RegExp(escapeRegExp('.' + c) + '[^_a-zA-Z0-9-]');
      return !rgx.test(text);
    });
    const filteredResults = undefinedClasses.length ? dropRegExp(dropClass(undefinedClasses)) : [];
    if (filteredResults.length) {
      console.log('List of ' + filteredResults.length + ' undefined CSS classes: ', filteredResults);
    } else {
      console.log('All CSS classes are defined!');
    }
  });
  function escapeRegExp(str) {
    return str.replace(/[\-\[\]\/\{\}\(\)\*\+\?\.\\\^\$\|]/g, "\\$&");
  }
})();
},{"core-js/modules/es.array.push.js":95}],6:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const adjustHexColor = (color, adjustment) => {
  var pound = false;
  if (color[0] == "#") {
    color = color.slice(1);
    pound = true;
  }
  var int = parseInt(color, 16);
  var r = (int >> 16) + adjustment;
  if (r > 255) r = 255;else if (r < 0) r = 0;
  var b = (int >> 8 & 0x00FF) + adjustment;
  if (b > 255) b = 255;else if (b < 0) b = 0;
  var g = (int & 0x0000FF) + adjustment;
  if (g > 255) g = 255;else if (g < 0) g = 0;
  [r, g, b] = [r, g, b].map(c => c <= 15 ? `0${c.toString(16)}` : c.toString(16));
  return (pound ? '#' : '') + r + b + g;
};
var _default = exports.default = adjustHexColor;
},{}],7:[function(require,module,exports){
"use strict";

// copy contents to clipboard
document.addEventListener('DOMContentLoaded', dom => {
  document.querySelectorAll('.clipboard').forEach(clipboard => {
    const id = clipboard.dataset.copy;
    clipboard.addEventListener('click', e => {
      const scratchpad = document.createElement('input');
      scratchpad.value = document.getElementById(id).innerText;
      document.body.appendChild(scratchpad);
      scratchpad.select();
      document.execCommand('copy');
      document.body.removeChild(scratchpad);
    });
  });
});
},{}],8:[function(require,module,exports){
"use strict";

var _color = _interopRequireDefault(require("./color"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
document.addEventListener('DOMContentLoaded', dom => {
  const urlParams = new URLSearchParams(window.location.search);
  const createCounter = config => {
    config.container.style.minWidth = '150px';
    config.container.style.maxWidth = '250px';
    config.container.style.position = 'relative';
    config.container.classList.add('mb-4', 'mx-auto', 'mb-md-0', 'mx-md-0', 'me-md-4');
    var graphic = new ProgressBar.Circle(config.container, {
      color: config.color,
      strokeWidth: 5,
      trailWidth: 1,
      easing: 'easeInOut',
      text: {
        autoStyleContainer: false
      },
      from: {
        color: (0, _color.default)(config.color, 50),
        width: 1
      },
      to: {
        color: config.color,
        width: 4
      },
      step: (state, circle) => {
        circle.path.setAttribute('stroke', state.color);
        circle.path.setAttribute('stroke-width', state.width);
        var value = Math.round(circle.value() * config.metric);
        circle.setText(`${value}<span style="display:block; margin-top:0px; line-height:22px; font-size: 1.5rem;">${config.text}</span>`);
      }
    });
    graphic.text.style.fontSize = '3rem';
    graphic.text.style.textAlign = 'center';
    return graphic;
  };
  const calculateCountdown = t => {
    const timer = {};
    const now = Math.floor(Date.now() / 1000);
    const timeLeft = t.end - now > 0 ? t.end - now : 0;
    timer.start = now > t.start ? true : false;
    timer.seconds = timeLeft % 60;
    timer.minutes = Math.floor(timeLeft % 3600 / 60);
    timer.hours = Math.floor(timeLeft % 86400 / 3600);
    timer.days = Math.floor(timeLeft / 86400);
    return timer;
  };
  [...document.getElementsByClassName('countdown')].forEach(e => {
    e.classList.add('d-flex', 'flex-column', 'flex-md-row', 'justify-content-center');
    const start = e.dataset.start;
    const end = e.dataset.end;
    const title = e.dataset.title;
    const expiry = e.dataset.expiry;
    const mode = e.dataset.mode;

    // mode (dark, light)
    const fontColor = mode === 'dark' ? 'text-light ' : '';

    // title
    const header = document.createElement('div');
    header.innerHTML = `<div class="pl-sm-4 mb-3 text-center text-md-left ${fontColor}display-4">${title}</div>`;
    e.before(header);

    // days
    var dcon = document.createElement('div');
    var dcfg = {
      color: '#fc6667',
      container: dcon,
      metric: end - start > 0 ? Math.floor((end - start) / 86400) : 0,
      text: 'Days'
    };
    var days = createCounter(dcfg);
    e.appendChild(dcon);

    // hours
    var hcon = document.createElement('div');
    var hcfg = {
      color: '#318efe',
      container: hcon,
      metric: 24,
      text: 'Hours'
    };
    var hours = createCounter(hcfg);
    e.appendChild(hcon);

    // minutes
    var mcon = document.createElement('div');
    var mcfg = {
      color: '#a0da7e',
      container: mcon,
      metric: 60,
      text: 'Minutes'
    };
    var minutes = createCounter(mcfg);
    e.appendChild(mcon);

    // seconds
    var scon = document.createElement('div');
    var scfg = {
      color: '#ffdb53',
      container: scon,
      metric: 60,
      text: 'Seconds'
    };
    var seconds = createCounter(scfg);
    scon.classList.remove('mb-4', 'me-md-4');
    e.appendChild(scon);

    // init counter
    const timer = () => {
      const timeFrame = calculateCountdown({
        start: start,
        end: end
      });
      var d = timeFrame.days / dcfg.metric;
      var h = timeFrame.hours / hcfg.metric;
      var m = timeFrame.minutes / mcfg.metric;
      var s = timeFrame.seconds / scfg.metric;
      if (d >= 0) {
        days.set(d);
      }
      if (h >= 0) {
        hours.set(h);
      }
      if (m >= 0) {
        minutes.set(m);
      }
      if (s >= 0) {
        seconds.set(s);
      }
      if (d == 0 && h == 0 && m == 0 && s == 0) {
        var notice = expiry ? `<span style="color: #fc6667;">${expiry}</span>` : title;
        header.innerHTML = `<h2 class="pl-sm-4 mb-3 text-center text-md-left">${notice}</h2>`;
      }
      if (d > 0 || h > 0 || m > 0 || s > 0) {
        setTimeout(timer, 1000);
      }
    };
    timer();
  });
});
},{"./color":6}],9:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// converts calendar date (e.g. 2021-7-18) into weekday integer
const setDay = day => {
  const week = new Array(7);
  week[0] = 'Sunday';
  week[1] = 'Monday';
  week[2] = 'Tuesday';
  week[3] = 'Wednesday';
  week[4] = 'Thursday';
  week[5] = 'Friday';
  week[6] = 'Saturday';
  const weekdayGMT = new Date(day);
  const weekday = new Date(weekdayGMT.valueOf() + weekdayGMT.getTimezoneOffset() * 60000);
  return week[weekday.getDay()];
};
var _default = exports.default = setDay;
},{}],10:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
// sets element display (hide/show)
const setDisplay = (elements, display) => {
  for (let i = 0; i < elements.length; i++) {
    elements[i].style.display = display ? 'block' : 'none';
  }
};
var _default = exports.default = setDisplay;
},{}],11:[function(require,module,exports){
"use strict";

// if we're embedded somewhere notify the style department
document.addEventListener('DOMContentLoaded', dom => {
  if (window.location != window.parent.location) {
    document.body.classList.remove('nav');
    document.body.classList.add('embed');
    const popout = document.getElementById('popout');
    if (popout) {
      popout.classList.remove('d-none');
    }
    ;
    const assignmentsLink = document.getElementById('assignment_link');
    if (assignmentsLink) {
      assignmentsLink.target = "_blank";
    }
    const plannerLink = document.getElementById('planner_link');
    if (plannerLink) {
      plannerLink.target = "_blank";
    }
    const tourLink = document.getElementById('tour_link');
    if (tourLink) {
      tourLink.target = "_blank";
    }
  }
  ;
});
},{}],12:[function(require,module,exports){
"use strict";

var _animation = _interopRequireDefault(require("./animation"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
/*
 * Example HTML:
 * <div class="fade-cycle-element-wrapper" data-rate="4000">
 *   <img class="fade-cycle-element" src="1.jpg" />
 *   <img class="fade-cycle-element" src="2.jpg" />
 *   <img class="fade-cycle-element" src="3.jpg" />
 * </div>
 */

const animate = _animation.default.animation;
document.addEventListener('DOMContentLoaded', dom => {
  const fadeRotate = document.querySelectorAll('.fade-cycle-element-wrapper');
  if (fadeRotate !== null) {
    fadeRotate.forEach(rotate => {
      var current = 0;
      var rate = rotate.dataset.rate;
      var duration = rate * 0.25;
      var elements = rotate.children;
      animate.fadeIn(elements[current], {
        duration: duration,
        complete: () => {
          return false;
        }
      });
      setInterval(function () {
        animate.fadeOut(elements[current], {
          duration: duration,
          complete: () => {
            return false;
          }
        });
        current = current == elements.length - 1 ? 0 : current + 1;
        animate.fadeIn(elements[current], {
          duration: duration,
          complete: () => {
            return false;
          }
        });
      }, rate);
    });
  }
}, false);
},{"./animation":2}],13:[function(require,module,exports){
"use strict";

// add a "about" and "report" links
document.addEventListener('DOMContentLoaded', dom => {
  const url = window.location.href;
  if (url.indexOf('download.php') == -1 && url.indexOf('timer.php') == -1 && url.indexOf('semester') == -1 && url.indexOf('semester') == -1 && url.indexOf('end') == -1) {
    const report = '<a href="/global/page/report.php"><i class="fal fa-exclamation"></i> Report a Problem</a>';
    const about = '<a href="/global/page/about.php"><i class="fal fa-info-circle"></i> About</a>';
    var links = report + about;
    if (url.indexOf('report.php') > -1) {
      links = `<div></div>${about}`;
    } else if (url.indexOf('about.php') > -1) {
      links = report;
    }
    const footnote = document.createElement('div');
    footnote.setAttribute('class', 'footnote');
    footnote.innerHTML = links;
    const container = document.querySelector('.container') ? document.querySelector('.container') : document.querySelector('.container-fluid');
    container.appendChild(footnote);
  }
});
},{}],14:[function(require,module,exports){
"use strict";

// form field customizations
document.addEventListener('DOMContentLoaded', dom => {
  // textarea
  const textarea = document.querySelectorAll('textarea');
  if (textarea.length != 0) {
    textarea.forEach(input => {
      autosize(input);
    });
  }

  // select
  var formSelect = document.querySelectorAll('select');
  formSelect.forEach(select => {
    select.classList.add('form-select');
  });
});
},{}],15:[function(require,module,exports){
"use strict";

var _style = _interopRequireDefault(require("./style"));
function _interopRequireDefault(obj) { return obj && obj.__esModule ? obj : { default: obj }; }
document.addEventListener('DOMContentLoaded', e => {
  // build out form-select navigation elements
  if (document.querySelector('.container').classList.contains('navselect')) {
    document.querySelectorAll('select').forEach(select => {
      select.classList.add('form-select');
      const opt = new Option('Click Here to Select', '');
      select.insertBefore(opt, select.firstChild);
      select.options[0].selected = 'selected';
      select.addEventListener('change', e => {
        window.location = select.value;
      });
    });
  }

  // isolated components for integrated assignment instructions
  if (document.getElementById('integrated')) {
    if (window.location.href.indexOf('show=') != -1) {
      document.getElementById('pagenav').style.display = 'none';
      document.querySelectorAll('hr').forEach(hr => {
        hr.style.display = 'none';
      });
    }
  }

  // attendance app helper
  if (document.getElementById('attendance')) {
    document.querySelector('#file').addEventListener('change', e => {
      var file = document.getElementById('file').files[0].name;
      var nextSibling = e.target.nextElementSibling;
      nextSibling.innerText = file;
      document.querySelector('#help-block').innerHTML = '<i class="fal fa-thumbs-up text-success"></i> Sweet! Now click the download button below.';
    });
  }

  // document (class) state control helper
  if (document.getElementsByClassName('fluid').length > 0) {
    var container = document.querySelector('.container');
    container.classList.add('container-fluid');
    container.classList.remove('fluid');
    container.classList.remove('container');
  }

  // code display helper
  if (document.getElementById('code-prettify')) {
    (0, _style.default)('li{list-style-type: decimal !important;}');
  }

  // hebrew document display
  if (document.getElementById('hebadmin')) {
    document.getElementById('unlock').addEventListener('click', e => {
      document.getElementById('access').value = 'unlock';
      document.getElementById('admin').submit();
    }, false);
    document.getElementById('lock').addEventListener('click', e => {
      document.getElementById('access').value = 'lock';
      document.getElementById('admin').submit();
    }, false);
  }
});
},{"./style":22}],16:[function(require,module,exports){
"use strict";

// link management
document.addEventListener('DOMContentLoaded', dom => {
  // add anchor placeholder for easy top scroll
  const topanchor = document.createElement('a');
  topanchor.id = 'top-anchor';
  document.body.insertBefore(topanchor, document.body.firstChild);

  // ATLA database link replacement: <a class="atla" href="record">link</a>
  document.querySelectorAll('.atla').forEach(link => {
    const record = link.getAttribute('href');
    link.href = `http://proxy.pba.edu/login?url=http://search.ebscohost.com/login.aspx?direct=true&AuthType=ip,uid&db=rfh&AN=${record}&site=ehost-live`;
  });

  // biblical reference link replacement: <a class="bible" href="text">link</a>
  document.querySelectorAll('.bible').forEach(link => {
    const text = link.getAttribute('href').replaceAll(' ', '+');
    link.href = `https://www.stepbible.org/?q=version=ESV|reference=${text}`;
  });

  // google search link replacement: <a class="google" href="text">link</a>
  document.querySelectorAll('.google').forEach(link => {
    const query = link.getAttribute('href').replaceAll(' ', '+');
    link.href = `https://www.google.com/search?q=${query}`;
    link.target = '_blank';
  });

  // amazon link replacement: <a class="amazon" href="text">link</a>
  document.querySelectorAll('.amazon').forEach(link => {
    const ref = link.getAttribute('href').replaceAll(' ', '+');
    link.href = `https://amzn.to/${ref}`;
    link.target = '_blank';
  });
});
},{}],17:[function(require,module,exports){
"use strict";

document.addEventListener('DOMContentLoaded', e => {
  if (document.getElementsByClassName('navsys').length > 0) {
    const topAnchor = document.getElementById('top-anchor');
    const topLink = document.getElementsByClassName('toggle-top');
    const toggleLinks = document.getElementsByClassName('toggle-link');
    const toggleTargets = document.getElementsByClassName('toggle-div');
    const toggleNav = document.getElementsByClassName('toggle-nav');

    // display element
    const fadeSlideOpen = e => {
      e.style.height = `${e.scrollHeight}px`;
      e.style.opacity = 1;
    };

    // hide element
    const fadeSlideClose = e => {
      e.style.height = '0px';
      e.style.opacity = 0;
    };

    // scroll to displayed element
    const smoothScroll = e => {
      const top = e.getBoundingClientRect().top + window.pageYOffset - 50;
      window.scrollTo({
        top: top,
        behavior: 'smooth'
      });
    };
    if (document.getElementById('navsys').dataset.navsys) {
      // initially hide all toggle divs

      [...toggleTargets].forEach(e => {
        e.style['transition'] = 'height 0.5s ease-in-out, opacity 0.5s';
        e.style.overflow = 'hidden';
        e.style.height = '0px';
        e.style.opacity = 0;
      });

      // make toggle items clicky
      [topLink, toggleLinks, toggleNav].forEach(clickyclass => {
        [...clickyclass].forEach(e => {
          e.classList.add('click');
        });
      });

      // auto display queried divs (example: togglepage.php?show=E1,E2)
      window.location.search.match(/show=([\w\-_,]+)/i);
      var show = RegExp.$1 !== '' ? RegExp.$1.split(',') : [];
      if (show.length) {
        show.forEach(div => {
          const activeLink = document.getElementById(div.replace('E', 'L'));
          if (activeLink !== null) {
            activeLink.classList.add('active');
            fadeSlideOpen(document.getElementById(div));
          }
        });
      }

      // click "top" link to scroll back to top of page
      [...topLink].forEach(top => {
        top.addEventListener('click', e => {
          e.preventDefault();
          smoothScroll(topAnchor);
        }, false);
      });

      // click any link to display corresponding div
      [...toggleLinks].forEach(link => {
        var target = document.getElementById(link.id.replace('L', 'E'));
        var tag = document.getElementById(link.id.replace('L', 'T'));
        var showTarget = '<i class="fal fa-plus-square"></i> Show ';
        var hideTarget = '<i class="fal fa-minus-square"></i> Hide ';
        if (tag) {
          tag.innerHTML = showTarget;
        }
        link.addEventListener('click', e => {
          link.classList.toggle('active');
          if (link.classList.contains('active')) {
            if (tag) {
              tag.innerHTML = hideTarget;
            } else {
              target.addEventListener('transitionend', function scroll() {
                this.removeEventListener('transitionend', scroll);
                smoothScroll(this);
              });
            }
            fadeSlideOpen(target);
          } else {
            if (tag) {
              tag.innerHTML = showTarget;
            }
            fadeSlideClose(target);
          }
          ;
        }, false);
      });

      // navigation show/hide all
      [...toggleNav].forEach(nav => {
        nav.addEventListener('click', e => {
          e.preventDefault();
          if (nav.classList.contains('active')) {
            [...toggleLinks].forEach(link => {
              link.classList.remove('active');
            });
            nav.classList.remove('active');
            nav.innerHTML = '<i class="fal fa-plus-square"></i> <a href="#">Show All</a>';
            [...toggleTargets].forEach(target => {
              fadeSlideClose(target);
            });
          } else {
            [...toggleLinks].forEach(link => {
              link.classList.add('active');
            });
            nav.classList.add('active');
            nav.innerHTML = '<i class="fal fa-minus-square"></i> <a href="#">Hide All</a>';
            [...toggleTargets].forEach(target => {
              fadeSlideOpen(target);
            });
          }
        });
      });
    } else {
      // parse query string
      var urlquery = new URLSearchParams(window.location.search);

      // create display query parameter for re-enable
      urlquery.set('d', '1');

      // set master toggle switch
      document.querySelector('.toggle-switch').innerHTML = '<a href="?' + urlquery.toString() + '"><i class="fad fa-compass"></i> Navigation On</a>';

      // show all content divs
      [...toggleTargets].forEach(target => {
        fadeSlideOpen(target);
      });

      // hide global expansion
      [...toggleNav].forEach(nav => {
        nav.style.display = 'none';
      });

      // hide top anchor
      [...topLink].forEach(link => {
        link.style.visibility = 'hidden';
      });
    }
  }
});
},{}],18:[function(require,module,exports){
"use strict";

document.addEventListener('DOMContentLoaded', e => {
  var popoverTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="popover"]'));
  var popoverList = popoverTriggerList.map(function (popoverTriggerEl) {
    return new bootstrap.Popover(popoverTriggerEl);
  });
});
},{}],19:[function(require,module,exports){
"use strict";

document.addEventListener('DOMContentLoaded', dom => {
  if (document.getElementById('tour')) {
    const validateTourResponse = response => {
      const filtered = response.replace(/^[^a-z]+$/i, '');
      return filtered ? response : filtered;
    };
    var form = document.getElementById('btt-input');
    if (form) {
      var clean = true;
      var tourCard = document.getElementById('card-tour');
      var buttonNew = document.getElementById('nav-new');
      var buttonOpen = document.getElementById('nav-open');
      var buttonSave = document.getElementById('state-save');
      var saveWarning = document.getElementById('save-changes');

      // how you doin?
      var updateProgress = function () {
        var total = 0;
        var response = 0;
        var inputs = document.querySelectorAll('textarea[name*="data"], select[name*="data"]');
        inputs.forEach(input => {
          const cachedResponse = input.value;
          var feedback = input.nextElementSibling;
          input.value = validateTourResponse(input.value);
          if (input.value) {
            feedback.classList.remove('text-danger');
            feedback.classList.add('text-success');
            feedback.innerHTML = '<i class="fas fa-check-circle"></i> Done';
            response = response + 1;
          } else {
            if (cachedResponse) {
              console.log(`The response "${cachedResponse}" is invalid.`);
            }
            feedback.classList.remove('text-success');
            feedback.classList.add('text-danger');
            feedback.innerHTML = '<i class="fas fa-exclamation-circle"></i> Needs Response';
          }
        });
        var progress = '<span class="d-none d-lg-inline">Progress: </span><span id="pval">' + Math.ceil(response / inputs.length * 100) + '</span>%';
        document.getElementById('tour-progress').innerHTML = progress;
        document.getElementById('save-progress').value = document.getElementById('pval').innerHTML;
      };

      // baby don't go
      window.addEventListener('beforeunload', e => {
        if (!clean) {
          e.preventDefault();
          e.returnValue = 'You have unsaved changes.';
        }
      });

      // form has unsaved responses, will pray for soul
      form.addEventListener('change', function () {
        clean = false;
        updateProgress();
        buttonNew.disabled = true;
        buttonOpen.disabled = true;
        saveWarning.innerHTML = '<i class="fal fa-exclamation-triangle"></i><span class="d-none d-lg-inline"> Unsaved Changes</span> | ';
      });

      // responses saved, can breath sigh of relief and close browser
      buttonSave.addEventListener('click', function () {
        clean = true;
        buttonNew.disabled = false;
        buttonOpen.disabled = false;
        saveWarning.innerHTML = '';
      });

      // for opening tours
      bsCustomFileInput.init();
    }
  }
});
},{}],20:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.nathan = exports.kathy = void 0;
/* Nathan Schedule
 * Mon: 01:00 - 03:00 (a)                             +  2.0
 * Tue: 02:00 - 03:00 (b)                             +  1.0
 * Wed: 09:00 - 12:00 (c)                             +  3.0
 * Thu: 02:00 - 03:00 (b)                             +  1.0
 * Fri: 09:00 - 12:00 (c)                             +  3.0
 * Total............................................. = 10.0
*/

/* Kathy Schedule
 * Mon: 09:00 - 11:00 (d)                             +  2.0
 * Tue: 09:00 - 11:00, 02:00-04:00 (e)                +  4.0
 * Wed: 09:00 - 11:00 (d)                             +  2.0
 * Thu: 09:00 - 11:00 (d)                             +  2.0
 * Fri:                                               +  0.0
 * Total............................................. = 10.0
*/

const a = ['1:00 PM', 8];
const b = ['2:00 PM', 4];
const c = ['9:00 AM', 12];
const d = ['9:00 AM', 8];
const e = [['9:00 AM', 8], ['2:00 PM', 8]];
const nathan = exports.nathan = {
  'Monday': a,
  'Tuesday': b,
  'Wednesday': c,
  'Thursday': b,
  'Friday': c,
  'Saturday': false,
  'Sunday': false
};
const kathy = exports.kathy = {
  'Monday': d,
  'Tuesday': e,
  'Wednesday': d,
  'Thursday': d,
  'Friday': false,
  'Saturday': false,
  'Sunday': false
};
},{}],21:[function(require,module,exports){
"use strict";

// tests if select option exists
HTMLSelectElement.prototype.contains = function (opt) {
  for (let i = 0; i < this.options.length; i++) {
    if (this.options[i].value == opt) {
      return true;
    }
  }
  return false;
};

// sets every <select> <option> to visible
HTMLSelectElement.prototype.show = function (opt) {
  for (const option of this) {
    if (opt === true || option.value == opt) {
      option.removeAttribute('hidden');
    }
  }
};

// hides option within select element
HTMLSelectElement.prototype.hide = function (opt) {
  for (const option of this) {
    if (opt === true || option.value == opt) {
      option.setAttribute('hidden', true);
    }
  }
};

// deslects all options within select element
HTMLSelectElement.prototype.clear = function () {
  for (const option of this) {
    option.selected = false;
  }
};
},{}],22:[function(require,module,exports){
"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
const addStyle = css => {
  var style = document.createElement('style');
  style.type = 'text/css';
  if (style.styleSheet) {
    style.styleSheet.cssText = css;
  } else {
    style.appendChild(document.createTextNode(css));
  }
  document.getElementsByTagName('head')[0].appendChild(style);
};
var _default = exports.default = addStyle;
},{}],23:[function(require,module,exports){
"use strict";

document.addEventListener('DOMContentLoaded', e => {
  var tooltipTriggerList = [].slice.call(document.querySelectorAll('[data-bs-toggle="tooltip"]'));
  var tooltipList = tooltipTriggerList.map(function (tooltipTriggerEl) {
    return new bootstrap.Tooltip(tooltipTriggerEl);
  });
});
},{}],24:[function(require,module,exports){
arguments[4][19][0].apply(exports,arguments)
},{"dup":19}],25:[function(require,module,exports){
"use strict";

document.addEventListener('DOMContentLoaded', dom => {
  document.querySelectorAll('.tree').forEach(tree => {
    tree.querySelectorAll('[data-bs-toggle]').forEach(branch => {
      const icon = document.createElement('span');
      icon.setAttribute('class', 'branch-collapsed');
      icon.innerHTML = '<i class="fal fa-plus-circle ms-1 me-2"></i>';
      branch.insertBefore(icon, branch.firstChild);
      branch.addEventListener('click', function () {
        if (icon.classList.contains('branch-collapsed')) {
          icon.setAttribute('class', 'branch-expanded');
          icon.innerHTML = '<i class="fal fa-minus-circle ms-1 me-2"></i>';
        } else if (icon.classList.contains('branch-expanded')) {
          icon.setAttribute('class', 'branch-collapsed');
          icon.innerHTML = '<i class="fal fa-plus-circle ms-1 me-2"></i>';
        }
        ;
      });
    });
  });
});
},{}],26:[function(require,module,exports){
"use strict";

(function () {
  window.addEventListener('load', function () {
    var forms = document.getElementsByClassName('custom-validation');
    var validation = Array.prototype.filter.call(forms, function (form) {
      form.addEventListener('submit', function (event) {
        if (form.checkValidity() === false) {
          event.preventDefault();
          event.stopPropagation();
        }
        form.classList.add('was-validated');
      }, false);
    });
  }, false);
})();
},{}],27:[function(require,module,exports){
'use strict';
var isCallable = require('../internals/is-callable');
var tryToString = require('../internals/try-to-string');

var $TypeError = TypeError;

// `Assert: IsCallable(argument) is true`
module.exports = function (argument) {
  if (isCallable(argument)) return argument;
  throw new $TypeError(tryToString(argument) + ' is not a function');
};

},{"../internals/is-callable":59,"../internals/try-to-string":89}],28:[function(require,module,exports){
'use strict';
var isObject = require('../internals/is-object');

var $String = String;
var $TypeError = TypeError;

// `Assert: Type(argument) is Object`
module.exports = function (argument) {
  if (isObject(argument)) return argument;
  throw new $TypeError($String(argument) + ' is not an object');
};

},{"../internals/is-object":62}],29:[function(require,module,exports){
'use strict';
var toIndexedObject = require('../internals/to-indexed-object');
var toAbsoluteIndex = require('../internals/to-absolute-index');
var lengthOfArrayLike = require('../internals/length-of-array-like');

// `Array.prototype.{ indexOf, includes }` methods implementation
var createMethod = function (IS_INCLUDES) {
  return function ($this, el, fromIndex) {
    var O = toIndexedObject($this);
    var length = lengthOfArrayLike(O);
    if (length === 0) return !IS_INCLUDES && -1;
    var index = toAbsoluteIndex(fromIndex, length);
    var value;
    // Array#includes uses SameValueZero equality algorithm
    // eslint-disable-next-line no-self-compare -- NaN check
    if (IS_INCLUDES && el !== el) while (length > index) {
      value = O[index++];
      // eslint-disable-next-line no-self-compare -- NaN check
      if (value !== value) return true;
    // Array#indexOf ignores holes, Array#includes - not
    } else for (;length > index; index++) {
      if ((IS_INCLUDES || index in O) && O[index] === el) return IS_INCLUDES || index || 0;
    } return !IS_INCLUDES && -1;
  };
};

module.exports = {
  // `Array.prototype.includes` method
  // https://tc39.es/ecma262/#sec-array.prototype.includes
  includes: createMethod(true),
  // `Array.prototype.indexOf` method
  // https://tc39.es/ecma262/#sec-array.prototype.indexof
  indexOf: createMethod(false)
};

},{"../internals/length-of-array-like":65,"../internals/to-absolute-index":82,"../internals/to-indexed-object":83}],30:[function(require,module,exports){
'use strict';
var DESCRIPTORS = require('../internals/descriptors');
var isArray = require('../internals/is-array');

var $TypeError = TypeError;
// eslint-disable-next-line es/no-object-getownpropertydescriptor -- safe
var getOwnPropertyDescriptor = Object.getOwnPropertyDescriptor;

// Safari < 13 does not throw an error in this case
var SILENT_ON_NON_WRITABLE_LENGTH_SET = DESCRIPTORS && !function () {
  // makes no sense without proper strict mode support
  if (this !== undefined) return true;
  try {
    // eslint-disable-next-line es/no-object-defineproperty -- safe
    Object.defineProperty([], 'length', { writable: false }).length = 1;
  } catch (error) {
    return error instanceof TypeError;
  }
}();

module.exports = SILENT_ON_NON_WRITABLE_LENGTH_SET ? function (O, length) {
  if (isArray(O) && !getOwnPropertyDescriptor(O, 'length').writable) {
    throw new $TypeError('Cannot set read only .length');
  } return O.length = length;
} : function (O, length) {
  return O.length = length;
};

},{"../internals/descriptors":37,"../internals/is-array":58}],31:[function(require,module,exports){
'use strict';
var uncurryThis = require('../internals/function-uncurry-this');

var toString = uncurryThis({}.toString);
var stringSlice = uncurryThis(''.slice);

module.exports = function (it) {
  return stringSlice(toString(it), 8, -1);
};

},{"../internals/function-uncurry-this":48}],32:[function(require,module,exports){
'use strict';
var hasOwn = require('../internals/has-own-property');
var ownKeys = require('../internals/own-keys');
var getOwnPropertyDescriptorModule = require('../internals/object-get-own-property-descriptor');
var definePropertyModule = require('../internals/object-define-property');

module.exports = function (target, source, exceptions) {
  var keys = ownKeys(source);
  var defineProperty = definePropertyModule.f;
  var getOwnPropertyDescriptor = getOwnPropertyDescriptorModule.f;
  for (var i = 0; i < keys.length; i++) {
    var key = keys[i];
    if (!hasOwn(target, key) && !(exceptions && hasOwn(exceptions, key))) {
      defineProperty(target, key, getOwnPropertyDescriptor(source, key));
    }
  }
};

},{"../internals/has-own-property":52,"../internals/object-define-property":68,"../internals/object-get-own-property-descriptor":69,"../internals/own-keys":76}],33:[function(require,module,exports){
'use strict';
var DESCRIPTORS = require('../internals/descriptors');
var definePropertyModule = require('../internals/object-define-property');
var createPropertyDescriptor = require('../internals/create-property-descriptor');

module.exports = DESCRIPTORS ? function (object, key, value) {
  return definePropertyModule.f(object, key, createPropertyDescriptor(1, value));
} : function (object, key, value) {
  object[key] = value;
  return object;
};

},{"../internals/create-property-descriptor":34,"../internals/descriptors":37,"../internals/object-define-property":68}],34:[function(require,module,exports){
'use strict';
module.exports = function (bitmap, value) {
  return {
    enumerable: !(bitmap & 1),
    configurable: !(bitmap & 2),
    writable: !(bitmap & 4),
    value: value
  };
};

},{}],35:[function(require,module,exports){
'use strict';
var isCallable = require('../internals/is-callable');
var definePropertyModule = require('../internals/object-define-property');
var makeBuiltIn = require('../internals/make-built-in');
var defineGlobalProperty = require('../internals/define-global-property');

module.exports = function (O, key, value, options) {
  if (!options) options = {};
  var simple = options.enumerable;
  var name = options.name !== undefined ? options.name : key;
  if (isCallable(value)) makeBuiltIn(value, name, options);
  if (options.global) {
    if (simple) O[key] = value;
    else defineGlobalProperty(key, value);
  } else {
    try {
      if (!options.unsafe) delete O[key];
      else if (O[key]) simple = true;
    } catch (error) { /* empty */ }
    if (simple) O[key] = value;
    else definePropertyModule.f(O, key, {
      value: value,
      enumerable: false,
      configurable: !options.nonConfigurable,
      writable: !options.nonWritable
    });
  } return O;
};

},{"../internals/define-global-property":36,"../internals/is-callable":59,"../internals/make-built-in":66,"../internals/object-define-property":68}],36:[function(require,module,exports){
'use strict';
var global = require('../internals/global');

// eslint-disable-next-line es/no-object-defineproperty -- safe
var defineProperty = Object.defineProperty;

module.exports = function (key, value) {
  try {
    defineProperty(global, key, { value: value, configurable: true, writable: true });
  } catch (error) {
    global[key] = value;
  } return value;
};

},{"../internals/global":51}],37:[function(require,module,exports){
'use strict';
var fails = require('../internals/fails');

// Detect IE8's incomplete defineProperty implementation
module.exports = !fails(function () {
  // eslint-disable-next-line es/no-object-defineproperty -- required for testing
  return Object.defineProperty({}, 1, { get: function () { return 7; } })[1] !== 7;
});

},{"../internals/fails":44}],38:[function(require,module,exports){
'use strict';
var global = require('../internals/global');
var isObject = require('../internals/is-object');

var document = global.document;
// typeof document.createElement is 'object' in old IE
var EXISTS = isObject(document) && isObject(document.createElement);

module.exports = function (it) {
  return EXISTS ? document.createElement(it) : {};
};

},{"../internals/global":51,"../internals/is-object":62}],39:[function(require,module,exports){
'use strict';
var $TypeError = TypeError;
var MAX_SAFE_INTEGER = 0x1FFFFFFFFFFFFF; // 2 ** 53 - 1 == 9007199254740991

module.exports = function (it) {
  if (it > MAX_SAFE_INTEGER) throw $TypeError('Maximum allowed index exceeded');
  return it;
};

},{}],40:[function(require,module,exports){
'use strict';
module.exports = typeof navigator != 'undefined' && String(navigator.userAgent) || '';

},{}],41:[function(require,module,exports){
'use strict';
var global = require('../internals/global');
var userAgent = require('../internals/engine-user-agent');

var process = global.process;
var Deno = global.Deno;
var versions = process && process.versions || Deno && Deno.version;
var v8 = versions && versions.v8;
var match, version;

if (v8) {
  match = v8.split('.');
  // in old Chrome, versions of V8 isn't V8 = Chrome / 10
  // but their correct versions are not interesting for us
  version = match[0] > 0 && match[0] < 4 ? 1 : +(match[0] + match[1]);
}

// BrowserFS NodeJS `process` polyfill incorrectly set `.v8` to `0.0`
// so check `userAgent` even if `.v8` exists, but 0
if (!version && userAgent) {
  match = userAgent.match(/Edge\/(\d+)/);
  if (!match || match[1] >= 74) {
    match = userAgent.match(/Chrome\/(\d+)/);
    if (match) version = +match[1];
  }
}

module.exports = version;

},{"../internals/engine-user-agent":40,"../internals/global":51}],42:[function(require,module,exports){
'use strict';
// IE8- don't enum bug keys
module.exports = [
  'constructor',
  'hasOwnProperty',
  'isPrototypeOf',
  'propertyIsEnumerable',
  'toLocaleString',
  'toString',
  'valueOf'
];

},{}],43:[function(require,module,exports){
'use strict';
var global = require('../internals/global');
var getOwnPropertyDescriptor = require('../internals/object-get-own-property-descriptor').f;
var createNonEnumerableProperty = require('../internals/create-non-enumerable-property');
var defineBuiltIn = require('../internals/define-built-in');
var defineGlobalProperty = require('../internals/define-global-property');
var copyConstructorProperties = require('../internals/copy-constructor-properties');
var isForced = require('../internals/is-forced');

/*
  options.target         - name of the target object
  options.global         - target is the global object
  options.stat           - export as static methods of target
  options.proto          - export as prototype methods of target
  options.real           - real prototype method for the `pure` version
  options.forced         - export even if the native feature is available
  options.bind           - bind methods to the target, required for the `pure` version
  options.wrap           - wrap constructors to preventing global pollution, required for the `pure` version
  options.unsafe         - use the simple assignment of property instead of delete + defineProperty
  options.sham           - add a flag to not completely full polyfills
  options.enumerable     - export as enumerable property
  options.dontCallGetSet - prevent calling a getter on target
  options.name           - the .name of the function if it does not match the key
*/
module.exports = function (options, source) {
  var TARGET = options.target;
  var GLOBAL = options.global;
  var STATIC = options.stat;
  var FORCED, target, key, targetProperty, sourceProperty, descriptor;
  if (GLOBAL) {
    target = global;
  } else if (STATIC) {
    target = global[TARGET] || defineGlobalProperty(TARGET, {});
  } else {
    target = global[TARGET] && global[TARGET].prototype;
  }
  if (target) for (key in source) {
    sourceProperty = source[key];
    if (options.dontCallGetSet) {
      descriptor = getOwnPropertyDescriptor(target, key);
      targetProperty = descriptor && descriptor.value;
    } else targetProperty = target[key];
    FORCED = isForced(GLOBAL ? key : TARGET + (STATIC ? '.' : '#') + key, options.forced);
    // contained in target
    if (!FORCED && targetProperty !== undefined) {
      if (typeof sourceProperty == typeof targetProperty) continue;
      copyConstructorProperties(sourceProperty, targetProperty);
    }
    // add a flag to not completely full polyfills
    if (options.sham || (targetProperty && targetProperty.sham)) {
      createNonEnumerableProperty(sourceProperty, 'sham', true);
    }
    defineBuiltIn(target, key, sourceProperty, options);
  }
};

},{"../internals/copy-constructor-properties":32,"../internals/create-non-enumerable-property":33,"../internals/define-built-in":35,"../internals/define-global-property":36,"../internals/global":51,"../internals/is-forced":60,"../internals/object-get-own-property-descriptor":69}],44:[function(require,module,exports){
'use strict';
module.exports = function (exec) {
  try {
    return !!exec();
  } catch (error) {
    return true;
  }
};

},{}],45:[function(require,module,exports){
'use strict';
var fails = require('../internals/fails');

module.exports = !fails(function () {
  // eslint-disable-next-line es/no-function-prototype-bind -- safe
  var test = (function () { /* empty */ }).bind();
  // eslint-disable-next-line no-prototype-builtins -- safe
  return typeof test != 'function' || test.hasOwnProperty('prototype');
});

},{"../internals/fails":44}],46:[function(require,module,exports){
'use strict';
var NATIVE_BIND = require('../internals/function-bind-native');

var call = Function.prototype.call;

module.exports = NATIVE_BIND ? call.bind(call) : function () {
  return call.apply(call, arguments);
};

},{"../internals/function-bind-native":45}],47:[function(require,module,exports){
'use strict';
var DESCRIPTORS = require('../internals/descriptors');
var hasOwn = require('../internals/has-own-property');

var FunctionPrototype = Function.prototype;
// eslint-disable-next-line es/no-object-getownpropertydescriptor -- safe
var getDescriptor = DESCRIPTORS && Object.getOwnPropertyDescriptor;

var EXISTS = hasOwn(FunctionPrototype, 'name');
// additional protection from minified / mangled / dropped function names
var PROPER = EXISTS && (function something() { /* empty */ }).name === 'something';
var CONFIGURABLE = EXISTS && (!DESCRIPTORS || (DESCRIPTORS && getDescriptor(FunctionPrototype, 'name').configurable));

module.exports = {
  EXISTS: EXISTS,
  PROPER: PROPER,
  CONFIGURABLE: CONFIGURABLE
};

},{"../internals/descriptors":37,"../internals/has-own-property":52}],48:[function(require,module,exports){
'use strict';
var NATIVE_BIND = require('../internals/function-bind-native');

var FunctionPrototype = Function.prototype;
var call = FunctionPrototype.call;
var uncurryThisWithBind = NATIVE_BIND && FunctionPrototype.bind.bind(call, call);

module.exports = NATIVE_BIND ? uncurryThisWithBind : function (fn) {
  return function () {
    return call.apply(fn, arguments);
  };
};

},{"../internals/function-bind-native":45}],49:[function(require,module,exports){
'use strict';
var global = require('../internals/global');
var isCallable = require('../internals/is-callable');

var aFunction = function (argument) {
  return isCallable(argument) ? argument : undefined;
};

module.exports = function (namespace, method) {
  return arguments.length < 2 ? aFunction(global[namespace]) : global[namespace] && global[namespace][method];
};

},{"../internals/global":51,"../internals/is-callable":59}],50:[function(require,module,exports){
'use strict';
var aCallable = require('../internals/a-callable');
var isNullOrUndefined = require('../internals/is-null-or-undefined');

// `GetMethod` abstract operation
// https://tc39.es/ecma262/#sec-getmethod
module.exports = function (V, P) {
  var func = V[P];
  return isNullOrUndefined(func) ? undefined : aCallable(func);
};

},{"../internals/a-callable":27,"../internals/is-null-or-undefined":61}],51:[function(require,module,exports){
(function (global){(function (){
'use strict';
var check = function (it) {
  return it && it.Math === Math && it;
};

// https://github.com/zloirock/core-js/issues/86#issuecomment-115759028
module.exports =
  // eslint-disable-next-line es/no-global-this -- safe
  check(typeof globalThis == 'object' && globalThis) ||
  check(typeof window == 'object' && window) ||
  // eslint-disable-next-line no-restricted-globals -- safe
  check(typeof self == 'object' && self) ||
  check(typeof global == 'object' && global) ||
  check(typeof this == 'object' && this) ||
  // eslint-disable-next-line no-new-func -- fallback
  (function () { return this; })() || Function('return this')();

}).call(this)}).call(this,typeof global !== "undefined" ? global : typeof self !== "undefined" ? self : typeof window !== "undefined" ? window : {})
},{}],52:[function(require,module,exports){
'use strict';
var uncurryThis = require('../internals/function-uncurry-this');
var toObject = require('../internals/to-object');

var hasOwnProperty = uncurryThis({}.hasOwnProperty);

// `HasOwnProperty` abstract operation
// https://tc39.es/ecma262/#sec-hasownproperty
// eslint-disable-next-line es/no-object-hasown -- safe
module.exports = Object.hasOwn || function hasOwn(it, key) {
  return hasOwnProperty(toObject(it), key);
};

},{"../internals/function-uncurry-this":48,"../internals/to-object":86}],53:[function(require,module,exports){
'use strict';
module.exports = {};

},{}],54:[function(require,module,exports){
'use strict';
var DESCRIPTORS = require('../internals/descriptors');
var fails = require('../internals/fails');
var createElement = require('../internals/document-create-element');

// Thanks to IE8 for its funny defineProperty
module.exports = !DESCRIPTORS && !fails(function () {
  // eslint-disable-next-line es/no-object-defineproperty -- required for testing
  return Object.defineProperty(createElement('div'), 'a', {
    get: function () { return 7; }
  }).a !== 7;
});

},{"../internals/descriptors":37,"../internals/document-create-element":38,"../internals/fails":44}],55:[function(require,module,exports){
'use strict';
var uncurryThis = require('../internals/function-uncurry-this');
var fails = require('../internals/fails');
var classof = require('../internals/classof-raw');

var $Object = Object;
var split = uncurryThis(''.split);

// fallback for non-array-like ES3 and non-enumerable old V8 strings
module.exports = fails(function () {
  // throws an error in rhino, see https://github.com/mozilla/rhino/issues/346
  // eslint-disable-next-line no-prototype-builtins -- safe
  return !$Object('z').propertyIsEnumerable(0);
}) ? function (it) {
  return classof(it) === 'String' ? split(it, '') : $Object(it);
} : $Object;

},{"../internals/classof-raw":31,"../internals/fails":44,"../internals/function-uncurry-this":48}],56:[function(require,module,exports){
'use strict';
var uncurryThis = require('../internals/function-uncurry-this');
var isCallable = require('../internals/is-callable');
var store = require('../internals/shared-store');

var functionToString = uncurryThis(Function.toString);

// this helper broken in `core-js@3.4.1-3.4.4`, so we can't use `shared` helper
if (!isCallable(store.inspectSource)) {
  store.inspectSource = function (it) {
    return functionToString(it);
  };
}

module.exports = store.inspectSource;

},{"../internals/function-uncurry-this":48,"../internals/is-callable":59,"../internals/shared-store":79}],57:[function(require,module,exports){
'use strict';
var NATIVE_WEAK_MAP = require('../internals/weak-map-basic-detection');
var global = require('../internals/global');
var isObject = require('../internals/is-object');
var createNonEnumerableProperty = require('../internals/create-non-enumerable-property');
var hasOwn = require('../internals/has-own-property');
var shared = require('../internals/shared-store');
var sharedKey = require('../internals/shared-key');
var hiddenKeys = require('../internals/hidden-keys');

var OBJECT_ALREADY_INITIALIZED = 'Object already initialized';
var TypeError = global.TypeError;
var WeakMap = global.WeakMap;
var set, get, has;

var enforce = function (it) {
  return has(it) ? get(it) : set(it, {});
};

var getterFor = function (TYPE) {
  return function (it) {
    var state;
    if (!isObject(it) || (state = get(it)).type !== TYPE) {
      throw new TypeError('Incompatible receiver, ' + TYPE + ' required');
    } return state;
  };
};

if (NATIVE_WEAK_MAP || shared.state) {
  var store = shared.state || (shared.state = new WeakMap());
  /* eslint-disable no-self-assign -- prototype methods protection */
  store.get = store.get;
  store.has = store.has;
  store.set = store.set;
  /* eslint-enable no-self-assign -- prototype methods protection */
  set = function (it, metadata) {
    if (store.has(it)) throw new TypeError(OBJECT_ALREADY_INITIALIZED);
    metadata.facade = it;
    store.set(it, metadata);
    return metadata;
  };
  get = function (it) {
    return store.get(it) || {};
  };
  has = function (it) {
    return store.has(it);
  };
} else {
  var STATE = sharedKey('state');
  hiddenKeys[STATE] = true;
  set = function (it, metadata) {
    if (hasOwn(it, STATE)) throw new TypeError(OBJECT_ALREADY_INITIALIZED);
    metadata.facade = it;
    createNonEnumerableProperty(it, STATE, metadata);
    return metadata;
  };
  get = function (it) {
    return hasOwn(it, STATE) ? it[STATE] : {};
  };
  has = function (it) {
    return hasOwn(it, STATE);
  };
}

module.exports = {
  set: set,
  get: get,
  has: has,
  enforce: enforce,
  getterFor: getterFor
};

},{"../internals/create-non-enumerable-property":33,"../internals/global":51,"../internals/has-own-property":52,"../internals/hidden-keys":53,"../internals/is-object":62,"../internals/shared-key":78,"../internals/shared-store":79,"../internals/weak-map-basic-detection":93}],58:[function(require,module,exports){
'use strict';
var classof = require('../internals/classof-raw');

// `IsArray` abstract operation
// https://tc39.es/ecma262/#sec-isarray
// eslint-disable-next-line es/no-array-isarray -- safe
module.exports = Array.isArray || function isArray(argument) {
  return classof(argument) === 'Array';
};

},{"../internals/classof-raw":31}],59:[function(require,module,exports){
'use strict';
// https://tc39.es/ecma262/#sec-IsHTMLDDA-internal-slot
var documentAll = typeof document == 'object' && document.all;

// `IsCallable` abstract operation
// https://tc39.es/ecma262/#sec-iscallable
// eslint-disable-next-line unicorn/no-typeof-undefined -- required for testing
module.exports = typeof documentAll == 'undefined' && documentAll !== undefined ? function (argument) {
  return typeof argument == 'function' || argument === documentAll;
} : function (argument) {
  return typeof argument == 'function';
};

},{}],60:[function(require,module,exports){
'use strict';
var fails = require('../internals/fails');
var isCallable = require('../internals/is-callable');

var replacement = /#|\.prototype\./;

var isForced = function (feature, detection) {
  var value = data[normalize(feature)];
  return value === POLYFILL ? true
    : value === NATIVE ? false
    : isCallable(detection) ? fails(detection)
    : !!detection;
};

var normalize = isForced.normalize = function (string) {
  return String(string).replace(replacement, '.').toLowerCase();
};

var data = isForced.data = {};
var NATIVE = isForced.NATIVE = 'N';
var POLYFILL = isForced.POLYFILL = 'P';

module.exports = isForced;

},{"../internals/fails":44,"../internals/is-callable":59}],61:[function(require,module,exports){
'use strict';
// we can't use just `it == null` since of `document.all` special case
// https://tc39.es/ecma262/#sec-IsHTMLDDA-internal-slot-aec
module.exports = function (it) {
  return it === null || it === undefined;
};

},{}],62:[function(require,module,exports){
'use strict';
var isCallable = require('../internals/is-callable');

module.exports = function (it) {
  return typeof it == 'object' ? it !== null : isCallable(it);
};

},{"../internals/is-callable":59}],63:[function(require,module,exports){
'use strict';
module.exports = false;

},{}],64:[function(require,module,exports){
'use strict';
var getBuiltIn = require('../internals/get-built-in');
var isCallable = require('../internals/is-callable');
var isPrototypeOf = require('../internals/object-is-prototype-of');
var USE_SYMBOL_AS_UID = require('../internals/use-symbol-as-uid');

var $Object = Object;

module.exports = USE_SYMBOL_AS_UID ? function (it) {
  return typeof it == 'symbol';
} : function (it) {
  var $Symbol = getBuiltIn('Symbol');
  return isCallable($Symbol) && isPrototypeOf($Symbol.prototype, $Object(it));
};

},{"../internals/get-built-in":49,"../internals/is-callable":59,"../internals/object-is-prototype-of":72,"../internals/use-symbol-as-uid":91}],65:[function(require,module,exports){
'use strict';
var toLength = require('../internals/to-length');

// `LengthOfArrayLike` abstract operation
// https://tc39.es/ecma262/#sec-lengthofarraylike
module.exports = function (obj) {
  return toLength(obj.length);
};

},{"../internals/to-length":85}],66:[function(require,module,exports){
'use strict';
var uncurryThis = require('../internals/function-uncurry-this');
var fails = require('../internals/fails');
var isCallable = require('../internals/is-callable');
var hasOwn = require('../internals/has-own-property');
var DESCRIPTORS = require('../internals/descriptors');
var CONFIGURABLE_FUNCTION_NAME = require('../internals/function-name').CONFIGURABLE;
var inspectSource = require('../internals/inspect-source');
var InternalStateModule = require('../internals/internal-state');

var enforceInternalState = InternalStateModule.enforce;
var getInternalState = InternalStateModule.get;
var $String = String;
// eslint-disable-next-line es/no-object-defineproperty -- safe
var defineProperty = Object.defineProperty;
var stringSlice = uncurryThis(''.slice);
var replace = uncurryThis(''.replace);
var join = uncurryThis([].join);

var CONFIGURABLE_LENGTH = DESCRIPTORS && !fails(function () {
  return defineProperty(function () { /* empty */ }, 'length', { value: 8 }).length !== 8;
});

var TEMPLATE = String(String).split('String');

var makeBuiltIn = module.exports = function (value, name, options) {
  if (stringSlice($String(name), 0, 7) === 'Symbol(') {
    name = '[' + replace($String(name), /^Symbol\(([^)]*)\).*$/, '$1') + ']';
  }
  if (options && options.getter) name = 'get ' + name;
  if (options && options.setter) name = 'set ' + name;
  if (!hasOwn(value, 'name') || (CONFIGURABLE_FUNCTION_NAME && value.name !== name)) {
    if (DESCRIPTORS) defineProperty(value, 'name', { value: name, configurable: true });
    else value.name = name;
  }
  if (CONFIGURABLE_LENGTH && options && hasOwn(options, 'arity') && value.length !== options.arity) {
    defineProperty(value, 'length', { value: options.arity });
  }
  try {
    if (options && hasOwn(options, 'constructor') && options.constructor) {
      if (DESCRIPTORS) defineProperty(value, 'prototype', { writable: false });
    // in V8 ~ Chrome 53, prototypes of some methods, like `Array.prototype.values`, are non-writable
    } else if (value.prototype) value.prototype = undefined;
  } catch (error) { /* empty */ }
  var state = enforceInternalState(value);
  if (!hasOwn(state, 'source')) {
    state.source = join(TEMPLATE, typeof name == 'string' ? name : '');
  } return value;
};

// add fake Function#toString for correct work wrapped methods / constructors with methods like LoDash isNative
// eslint-disable-next-line no-extend-native -- required
Function.prototype.toString = makeBuiltIn(function toString() {
  return isCallable(this) && getInternalState(this).source || inspectSource(this);
}, 'toString');

},{"../internals/descriptors":37,"../internals/fails":44,"../internals/function-name":47,"../internals/function-uncurry-this":48,"../internals/has-own-property":52,"../internals/inspect-source":56,"../internals/internal-state":57,"../internals/is-callable":59}],67:[function(require,module,exports){
'use strict';
var ceil = Math.ceil;
var floor = Math.floor;

// `Math.trunc` method
// https://tc39.es/ecma262/#sec-math.trunc
// eslint-disable-next-line es/no-math-trunc -- safe
module.exports = Math.trunc || function trunc(x) {
  var n = +x;
  return (n > 0 ? floor : ceil)(n);
};

},{}],68:[function(require,module,exports){
'use strict';
var DESCRIPTORS = require('../internals/descriptors');
var IE8_DOM_DEFINE = require('../internals/ie8-dom-define');
var V8_PROTOTYPE_DEFINE_BUG = require('../internals/v8-prototype-define-bug');
var anObject = require('../internals/an-object');
var toPropertyKey = require('../internals/to-property-key');

var $TypeError = TypeError;
// eslint-disable-next-line es/no-object-defineproperty -- safe
var $defineProperty = Object.defineProperty;
// eslint-disable-next-line es/no-object-getownpropertydescriptor -- safe
var $getOwnPropertyDescriptor = Object.getOwnPropertyDescriptor;
var ENUMERABLE = 'enumerable';
var CONFIGURABLE = 'configurable';
var WRITABLE = 'writable';

// `Object.defineProperty` method
// https://tc39.es/ecma262/#sec-object.defineproperty
exports.f = DESCRIPTORS ? V8_PROTOTYPE_DEFINE_BUG ? function defineProperty(O, P, Attributes) {
  anObject(O);
  P = toPropertyKey(P);
  anObject(Attributes);
  if (typeof O === 'function' && P === 'prototype' && 'value' in Attributes && WRITABLE in Attributes && !Attributes[WRITABLE]) {
    var current = $getOwnPropertyDescriptor(O, P);
    if (current && current[WRITABLE]) {
      O[P] = Attributes.value;
      Attributes = {
        configurable: CONFIGURABLE in Attributes ? Attributes[CONFIGURABLE] : current[CONFIGURABLE],
        enumerable: ENUMERABLE in Attributes ? Attributes[ENUMERABLE] : current[ENUMERABLE],
        writable: false
      };
    }
  } return $defineProperty(O, P, Attributes);
} : $defineProperty : function defineProperty(O, P, Attributes) {
  anObject(O);
  P = toPropertyKey(P);
  anObject(Attributes);
  if (IE8_DOM_DEFINE) try {
    return $defineProperty(O, P, Attributes);
  } catch (error) { /* empty */ }
  if ('get' in Attributes || 'set' in Attributes) throw new $TypeError('Accessors not supported');
  if ('value' in Attributes) O[P] = Attributes.value;
  return O;
};

},{"../internals/an-object":28,"../internals/descriptors":37,"../internals/ie8-dom-define":54,"../internals/to-property-key":88,"../internals/v8-prototype-define-bug":92}],69:[function(require,module,exports){
'use strict';
var DESCRIPTORS = require('../internals/descriptors');
var call = require('../internals/function-call');
var propertyIsEnumerableModule = require('../internals/object-property-is-enumerable');
var createPropertyDescriptor = require('../internals/create-property-descriptor');
var toIndexedObject = require('../internals/to-indexed-object');
var toPropertyKey = require('../internals/to-property-key');
var hasOwn = require('../internals/has-own-property');
var IE8_DOM_DEFINE = require('../internals/ie8-dom-define');

// eslint-disable-next-line es/no-object-getownpropertydescriptor -- safe
var $getOwnPropertyDescriptor = Object.getOwnPropertyDescriptor;

// `Object.getOwnPropertyDescriptor` method
// https://tc39.es/ecma262/#sec-object.getownpropertydescriptor
exports.f = DESCRIPTORS ? $getOwnPropertyDescriptor : function getOwnPropertyDescriptor(O, P) {
  O = toIndexedObject(O);
  P = toPropertyKey(P);
  if (IE8_DOM_DEFINE) try {
    return $getOwnPropertyDescriptor(O, P);
  } catch (error) { /* empty */ }
  if (hasOwn(O, P)) return createPropertyDescriptor(!call(propertyIsEnumerableModule.f, O, P), O[P]);
};

},{"../internals/create-property-descriptor":34,"../internals/descriptors":37,"../internals/function-call":46,"../internals/has-own-property":52,"../internals/ie8-dom-define":54,"../internals/object-property-is-enumerable":74,"../internals/to-indexed-object":83,"../internals/to-property-key":88}],70:[function(require,module,exports){
'use strict';
var internalObjectKeys = require('../internals/object-keys-internal');
var enumBugKeys = require('../internals/enum-bug-keys');

var hiddenKeys = enumBugKeys.concat('length', 'prototype');

// `Object.getOwnPropertyNames` method
// https://tc39.es/ecma262/#sec-object.getownpropertynames
// eslint-disable-next-line es/no-object-getownpropertynames -- safe
exports.f = Object.getOwnPropertyNames || function getOwnPropertyNames(O) {
  return internalObjectKeys(O, hiddenKeys);
};

},{"../internals/enum-bug-keys":42,"../internals/object-keys-internal":73}],71:[function(require,module,exports){
'use strict';
// eslint-disable-next-line es/no-object-getownpropertysymbols -- safe
exports.f = Object.getOwnPropertySymbols;

},{}],72:[function(require,module,exports){
'use strict';
var uncurryThis = require('../internals/function-uncurry-this');

module.exports = uncurryThis({}.isPrototypeOf);

},{"../internals/function-uncurry-this":48}],73:[function(require,module,exports){
'use strict';
var uncurryThis = require('../internals/function-uncurry-this');
var hasOwn = require('../internals/has-own-property');
var toIndexedObject = require('../internals/to-indexed-object');
var indexOf = require('../internals/array-includes').indexOf;
var hiddenKeys = require('../internals/hidden-keys');

var push = uncurryThis([].push);

module.exports = function (object, names) {
  var O = toIndexedObject(object);
  var i = 0;
  var result = [];
  var key;
  for (key in O) !hasOwn(hiddenKeys, key) && hasOwn(O, key) && push(result, key);
  // Don't enum bug & hidden keys
  while (names.length > i) if (hasOwn(O, key = names[i++])) {
    ~indexOf(result, key) || push(result, key);
  }
  return result;
};

},{"../internals/array-includes":29,"../internals/function-uncurry-this":48,"../internals/has-own-property":52,"../internals/hidden-keys":53,"../internals/to-indexed-object":83}],74:[function(require,module,exports){
'use strict';
var $propertyIsEnumerable = {}.propertyIsEnumerable;
// eslint-disable-next-line es/no-object-getownpropertydescriptor -- safe
var getOwnPropertyDescriptor = Object.getOwnPropertyDescriptor;

// Nashorn ~ JDK8 bug
var NASHORN_BUG = getOwnPropertyDescriptor && !$propertyIsEnumerable.call({ 1: 2 }, 1);

// `Object.prototype.propertyIsEnumerable` method implementation
// https://tc39.es/ecma262/#sec-object.prototype.propertyisenumerable
exports.f = NASHORN_BUG ? function propertyIsEnumerable(V) {
  var descriptor = getOwnPropertyDescriptor(this, V);
  return !!descriptor && descriptor.enumerable;
} : $propertyIsEnumerable;

},{}],75:[function(require,module,exports){
'use strict';
var call = require('../internals/function-call');
var isCallable = require('../internals/is-callable');
var isObject = require('../internals/is-object');

var $TypeError = TypeError;

// `OrdinaryToPrimitive` abstract operation
// https://tc39.es/ecma262/#sec-ordinarytoprimitive
module.exports = function (input, pref) {
  var fn, val;
  if (pref === 'string' && isCallable(fn = input.toString) && !isObject(val = call(fn, input))) return val;
  if (isCallable(fn = input.valueOf) && !isObject(val = call(fn, input))) return val;
  if (pref !== 'string' && isCallable(fn = input.toString) && !isObject(val = call(fn, input))) return val;
  throw new $TypeError("Can't convert object to primitive value");
};

},{"../internals/function-call":46,"../internals/is-callable":59,"../internals/is-object":62}],76:[function(require,module,exports){
'use strict';
var getBuiltIn = require('../internals/get-built-in');
var uncurryThis = require('../internals/function-uncurry-this');
var getOwnPropertyNamesModule = require('../internals/object-get-own-property-names');
var getOwnPropertySymbolsModule = require('../internals/object-get-own-property-symbols');
var anObject = require('../internals/an-object');

var concat = uncurryThis([].concat);

// all object keys, includes non-enumerable and symbols
module.exports = getBuiltIn('Reflect', 'ownKeys') || function ownKeys(it) {
  var keys = getOwnPropertyNamesModule.f(anObject(it));
  var getOwnPropertySymbols = getOwnPropertySymbolsModule.f;
  return getOwnPropertySymbols ? concat(keys, getOwnPropertySymbols(it)) : keys;
};

},{"../internals/an-object":28,"../internals/function-uncurry-this":48,"../internals/get-built-in":49,"../internals/object-get-own-property-names":70,"../internals/object-get-own-property-symbols":71}],77:[function(require,module,exports){
'use strict';
var isNullOrUndefined = require('../internals/is-null-or-undefined');

var $TypeError = TypeError;

// `RequireObjectCoercible` abstract operation
// https://tc39.es/ecma262/#sec-requireobjectcoercible
module.exports = function (it) {
  if (isNullOrUndefined(it)) throw new $TypeError("Can't call method on " + it);
  return it;
};

},{"../internals/is-null-or-undefined":61}],78:[function(require,module,exports){
'use strict';
var shared = require('../internals/shared');
var uid = require('../internals/uid');

var keys = shared('keys');

module.exports = function (key) {
  return keys[key] || (keys[key] = uid(key));
};

},{"../internals/shared":80,"../internals/uid":90}],79:[function(require,module,exports){
'use strict';
var IS_PURE = require('../internals/is-pure');
var globalThis = require('../internals/global');
var defineGlobalProperty = require('../internals/define-global-property');

var SHARED = '__core-js_shared__';
var store = module.exports = globalThis[SHARED] || defineGlobalProperty(SHARED, {});

(store.versions || (store.versions = [])).push({
  version: '3.37.0',
  mode: IS_PURE ? 'pure' : 'global',
  copyright: '© 2014-2024 Denis Pushkarev (zloirock.ru)',
  license: 'https://github.com/zloirock/core-js/blob/v3.37.0/LICENSE',
  source: 'https://github.com/zloirock/core-js'
});

},{"../internals/define-global-property":36,"../internals/global":51,"../internals/is-pure":63}],80:[function(require,module,exports){
'use strict';
var store = require('../internals/shared-store');

module.exports = function (key, value) {
  return store[key] || (store[key] = value || {});
};

},{"../internals/shared-store":79}],81:[function(require,module,exports){
'use strict';
/* eslint-disable es/no-symbol -- required for testing */
var V8_VERSION = require('../internals/engine-v8-version');
var fails = require('../internals/fails');
var global = require('../internals/global');

var $String = global.String;

// eslint-disable-next-line es/no-object-getownpropertysymbols -- required for testing
module.exports = !!Object.getOwnPropertySymbols && !fails(function () {
  var symbol = Symbol('symbol detection');
  // Chrome 38 Symbol has incorrect toString conversion
  // `get-own-property-symbols` polyfill symbols converted to object are not Symbol instances
  // nb: Do not call `String` directly to avoid this being optimized out to `symbol+''` which will,
  // of course, fail.
  return !$String(symbol) || !(Object(symbol) instanceof Symbol) ||
    // Chrome 38-40 symbols are not inherited from DOM collections prototypes to instances
    !Symbol.sham && V8_VERSION && V8_VERSION < 41;
});

},{"../internals/engine-v8-version":41,"../internals/fails":44,"../internals/global":51}],82:[function(require,module,exports){
'use strict';
var toIntegerOrInfinity = require('../internals/to-integer-or-infinity');

var max = Math.max;
var min = Math.min;

// Helper for a popular repeating case of the spec:
// Let integer be ? ToInteger(index).
// If integer < 0, let result be max((length + integer), 0); else let result be min(integer, length).
module.exports = function (index, length) {
  var integer = toIntegerOrInfinity(index);
  return integer < 0 ? max(integer + length, 0) : min(integer, length);
};

},{"../internals/to-integer-or-infinity":84}],83:[function(require,module,exports){
'use strict';
// toObject with fallback for non-array-like ES3 strings
var IndexedObject = require('../internals/indexed-object');
var requireObjectCoercible = require('../internals/require-object-coercible');

module.exports = function (it) {
  return IndexedObject(requireObjectCoercible(it));
};

},{"../internals/indexed-object":55,"../internals/require-object-coercible":77}],84:[function(require,module,exports){
'use strict';
var trunc = require('../internals/math-trunc');

// `ToIntegerOrInfinity` abstract operation
// https://tc39.es/ecma262/#sec-tointegerorinfinity
module.exports = function (argument) {
  var number = +argument;
  // eslint-disable-next-line no-self-compare -- NaN check
  return number !== number || number === 0 ? 0 : trunc(number);
};

},{"../internals/math-trunc":67}],85:[function(require,module,exports){
'use strict';
var toIntegerOrInfinity = require('../internals/to-integer-or-infinity');

var min = Math.min;

// `ToLength` abstract operation
// https://tc39.es/ecma262/#sec-tolength
module.exports = function (argument) {
  var len = toIntegerOrInfinity(argument);
  return len > 0 ? min(len, 0x1FFFFFFFFFFFFF) : 0; // 2 ** 53 - 1 == 9007199254740991
};

},{"../internals/to-integer-or-infinity":84}],86:[function(require,module,exports){
'use strict';
var requireObjectCoercible = require('../internals/require-object-coercible');

var $Object = Object;

// `ToObject` abstract operation
// https://tc39.es/ecma262/#sec-toobject
module.exports = function (argument) {
  return $Object(requireObjectCoercible(argument));
};

},{"../internals/require-object-coercible":77}],87:[function(require,module,exports){
'use strict';
var call = require('../internals/function-call');
var isObject = require('../internals/is-object');
var isSymbol = require('../internals/is-symbol');
var getMethod = require('../internals/get-method');
var ordinaryToPrimitive = require('../internals/ordinary-to-primitive');
var wellKnownSymbol = require('../internals/well-known-symbol');

var $TypeError = TypeError;
var TO_PRIMITIVE = wellKnownSymbol('toPrimitive');

// `ToPrimitive` abstract operation
// https://tc39.es/ecma262/#sec-toprimitive
module.exports = function (input, pref) {
  if (!isObject(input) || isSymbol(input)) return input;
  var exoticToPrim = getMethod(input, TO_PRIMITIVE);
  var result;
  if (exoticToPrim) {
    if (pref === undefined) pref = 'default';
    result = call(exoticToPrim, input, pref);
    if (!isObject(result) || isSymbol(result)) return result;
    throw new $TypeError("Can't convert object to primitive value");
  }
  if (pref === undefined) pref = 'number';
  return ordinaryToPrimitive(input, pref);
};

},{"../internals/function-call":46,"../internals/get-method":50,"../internals/is-object":62,"../internals/is-symbol":64,"../internals/ordinary-to-primitive":75,"../internals/well-known-symbol":94}],88:[function(require,module,exports){
'use strict';
var toPrimitive = require('../internals/to-primitive');
var isSymbol = require('../internals/is-symbol');

// `ToPropertyKey` abstract operation
// https://tc39.es/ecma262/#sec-topropertykey
module.exports = function (argument) {
  var key = toPrimitive(argument, 'string');
  return isSymbol(key) ? key : key + '';
};

},{"../internals/is-symbol":64,"../internals/to-primitive":87}],89:[function(require,module,exports){
'use strict';
var $String = String;

module.exports = function (argument) {
  try {
    return $String(argument);
  } catch (error) {
    return 'Object';
  }
};

},{}],90:[function(require,module,exports){
'use strict';
var uncurryThis = require('../internals/function-uncurry-this');

var id = 0;
var postfix = Math.random();
var toString = uncurryThis(1.0.toString);

module.exports = function (key) {
  return 'Symbol(' + (key === undefined ? '' : key) + ')_' + toString(++id + postfix, 36);
};

},{"../internals/function-uncurry-this":48}],91:[function(require,module,exports){
'use strict';
/* eslint-disable es/no-symbol -- required for testing */
var NATIVE_SYMBOL = require('../internals/symbol-constructor-detection');

module.exports = NATIVE_SYMBOL
  && !Symbol.sham
  && typeof Symbol.iterator == 'symbol';

},{"../internals/symbol-constructor-detection":81}],92:[function(require,module,exports){
'use strict';
var DESCRIPTORS = require('../internals/descriptors');
var fails = require('../internals/fails');

// V8 ~ Chrome 36-
// https://bugs.chromium.org/p/v8/issues/detail?id=3334
module.exports = DESCRIPTORS && fails(function () {
  // eslint-disable-next-line es/no-object-defineproperty -- required for testing
  return Object.defineProperty(function () { /* empty */ }, 'prototype', {
    value: 42,
    writable: false
  }).prototype !== 42;
});

},{"../internals/descriptors":37,"../internals/fails":44}],93:[function(require,module,exports){
'use strict';
var global = require('../internals/global');
var isCallable = require('../internals/is-callable');

var WeakMap = global.WeakMap;

module.exports = isCallable(WeakMap) && /native code/.test(String(WeakMap));

},{"../internals/global":51,"../internals/is-callable":59}],94:[function(require,module,exports){
'use strict';
var global = require('../internals/global');
var shared = require('../internals/shared');
var hasOwn = require('../internals/has-own-property');
var uid = require('../internals/uid');
var NATIVE_SYMBOL = require('../internals/symbol-constructor-detection');
var USE_SYMBOL_AS_UID = require('../internals/use-symbol-as-uid');

var Symbol = global.Symbol;
var WellKnownSymbolsStore = shared('wks');
var createWellKnownSymbol = USE_SYMBOL_AS_UID ? Symbol['for'] || Symbol : Symbol && Symbol.withoutSetter || uid;

module.exports = function (name) {
  if (!hasOwn(WellKnownSymbolsStore, name)) {
    WellKnownSymbolsStore[name] = NATIVE_SYMBOL && hasOwn(Symbol, name)
      ? Symbol[name]
      : createWellKnownSymbol('Symbol.' + name);
  } return WellKnownSymbolsStore[name];
};

},{"../internals/global":51,"../internals/has-own-property":52,"../internals/shared":80,"../internals/symbol-constructor-detection":81,"../internals/uid":90,"../internals/use-symbol-as-uid":91}],95:[function(require,module,exports){
'use strict';
var $ = require('../internals/export');
var toObject = require('../internals/to-object');
var lengthOfArrayLike = require('../internals/length-of-array-like');
var setArrayLength = require('../internals/array-set-length');
var doesNotExceedSafeInteger = require('../internals/does-not-exceed-safe-integer');
var fails = require('../internals/fails');

var INCORRECT_TO_LENGTH = fails(function () {
  return [].push.call({ length: 0x100000000 }, 1) !== 4294967297;
});

// V8 <= 121 and Safari <= 15.4; FF < 23 throws InternalError
// https://bugs.chromium.org/p/v8/issues/detail?id=12681
var properErrorOnNonWritableLength = function () {
  try {
    // eslint-disable-next-line es/no-object-defineproperty -- safe
    Object.defineProperty([], 'length', { writable: false }).push();
  } catch (error) {
    return error instanceof TypeError;
  }
};

var FORCED = INCORRECT_TO_LENGTH || !properErrorOnNonWritableLength();

// `Array.prototype.push` method
// https://tc39.es/ecma262/#sec-array.prototype.push
$({ target: 'Array', proto: true, arity: 1, forced: FORCED }, {
  // eslint-disable-next-line no-unused-vars -- required for `.length`
  push: function push(item) {
    var O = toObject(this);
    var len = lengthOfArrayLike(O);
    var argCount = arguments.length;
    doesNotExceedSafeInteger(len + argCount);
    for (var i = 0; i < argCount; i++) {
      O[len] = arguments[i];
      len++;
    }
    setArrayLength(O, len);
    return len;
  }
});

},{"../internals/array-set-length":30,"../internals/does-not-exceed-safe-integer":39,"../internals/export":43,"../internals/fails":44,"../internals/length-of-array-like":65,"../internals/to-object":86}]},{},[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20,21,22,23,24,25,26]);
